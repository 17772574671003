import { Raycaster, Vector2 } from 'three';

export class RaycastService {
    constructor(camera, scene) {
        this.camera = camera;
        this.scene = scene;
        this.raycaster = new Raycaster();
        this.pointer = new Vector2();
    }

    initializeRaycaster(camera, scene) {
        this.camera = camera;
        this.scene = scene;
        this.raycaster = new Raycaster();
        this.pointer = new Vector2();
    }

    updatePointerPosition(event) {
        const rect = this.getCustomBoundingClientRect();
        this.pointer.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        this.pointer.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
    }

    performRaycast(objects) {
        this.raycaster.setFromCamera(this.pointer, this.camera);
        return this.raycaster.intersectObjects(objects, true);
    }

    getCustomBoundingClientRect() {
        const element = document.getElementById('threejs-viewport');
        return element.getBoundingClientRect();
    }
}

'use strict';

export class SolutionObject
{
    _name = '';
    _unit = '';
    _colorData = [];
    _legend = null;

    constructor(
        name,
        unit,
        colorData,
        legend
    ) {
        this._name = name;
        this._unit = unit;
        this._colorData = colorData;
        this._legend = legend;
    }


    get name() {
        return this._name;
    }

    get unit() {
        return this._unit;
    }

    get colorData() {
        return this._colorData;
    }

    get legend() {
        return this._legend;
    }
}
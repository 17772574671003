'use strict';


export class ColorLegendCreator
{
    _legendColorIndex = null;
    _legendUnit = null;
    _legendName = null;

    constructor() {
    }

    setLegendConfiguration(solutionObject)
    {
        this._legendColorIndex = solutionObject.legend;
        this._legendUnit = solutionObject.unit;
        this._legendName = solutionObject.name;
    }

    createLegend() {
        if (this._legendColorIndex.colorIndexSize > 0) {
            let legendBanner = document.createElement('div');
            legendBanner.className = 'legend-banner';
            legendBanner.setAttribute('id', 'color-legend');

            let name = document.createElement('div');
            name.innerText = 'Name: ' + this._legendName;
            let unit = document.createElement('div');
            unit.innerText = 'Einheit: ' + this._legendUnit;
            legendBanner.append(name);
            legendBanner.append(unit);

        const entries = Object.entries(this._legendColorIndex.colorIndex);
        entries.sort((a, b) => parseFloat(b[0]) - parseFloat(a[0]));

        for (const [colorKey, colorValue] of entries) {
            let colorWrapper = document.createElement('div');
            colorWrapper.className = 'color-wrapper';

            let colorSquare = document.createElement('span');
            colorSquare.className = 'color-square';
            colorSquare.setAttribute('style', `background-color:rgb(${colorValue.r * 255},${colorValue.g * 255},${colorValue.b * 255})`);

            let roundedColorKey = Number.parseFloat(colorKey).toPrecision(3);

            colorSquare.setAttribute('title', roundedColorKey);

            let colorDescription = document.createElement('span');
            colorDescription.className = 'color-description';
            colorDescription.innerText = roundedColorKey;

            colorWrapper.append(colorSquare, colorDescription);
            legendBanner.appendChild(colorWrapper);
        }

            return legendBanner;
        } else {
            return '';
        }
    }
}
'use strict';


import {SolutionObject} from "../models/SolutionObject.js";
import {Color} from "three";
import {LegendColorIndex} from "../models/LegendColorIndex.js";

export class SolutionFactory
{
    constructor() {
    }

    createSolutionModelFromData(solutiondata)
    {
        if (solutiondata === undefined || solutiondata === null) {
            return null;
        }
        else {
            let name = solutiondata.name ?? '';
            let unit = solutiondata.unit ?? '';
            let colorData = solutiondata.data ?? [];
            let legend = this._createLegendColorIndex(solutiondata.legend ?? []);

            return new SolutionObject(name, unit, colorData, legend);
        }
    }

    _createLegendColorIndex(legend) {
        const colorIndex = {};
        const legendSize = legend.length;
        const colorIndexReference = {};
        let legendColorIndex = null;

        if (legendSize > 3) { // minimum required fields are 4 (r, g, b, referenceIndex)
            for (let i = 0; i < legendSize; i += 4)
            {
                let colorEntry = new Color(
                    legend[i],
                    legend[i + 1],
                    legend[i + 2]
                );

                let indexKey = legend[i + 3].toString();
                colorIndex[indexKey] = colorEntry;
                colorIndexReference[indexKey] = legend[i + 3];
            }

           legendColorIndex = new LegendColorIndex(colorIndex, colorIndexReference);
        }

        return legendColorIndex;
    }
}
'use strict';

import {
    BufferAttribute,
    BufferGeometry,
    Float32BufferAttribute,
    Mesh,
    MeshBasicMaterial,
} from "three";

export class SquareHelper
{
    _mesh = null;

    constructor() {
        this._init();
    }

    _init()
    {
        const geometry = new BufferGeometry();
        geometry.setAttribute( 'position', new BufferAttribute( new Float32Array( 4 * 3 ), 3 ) );
        geometry.setAttribute( 'color',  new Float32BufferAttribute([1,1,1,1,1,1,1,1,1,1,1,1], 3));

        const material = new MeshBasicMaterial( { color: 0xffffff, vertexColors: true } );

        this._mesh = new Mesh( geometry, material );
    }

    setMesh(mesh)
    {
        let color = [];
        this._mesh.geometry.attributes.position = mesh;
        this._mesh.geometry.attributes.color = color;
    }



    getModel()
    {
        return this._mesh;
    }
}
import * as THREE from "three";
import {
    BufferAttribute,
    BufferGeometry,
    Color,
    DynamicDrawUsage,
    Float32BufferAttribute,
    Mesh,
    MeshBasicMaterial,
    Box3,
    Vector3,
    Group,
    LineBasicMaterial,
    LineSegments
} from "three";
import { Coordinates } from "../models/Coordinates.js";
import { ColorMap } from "../models/ColorMap.js";

export class ModelGenerator {
    _mesh = null;
    _threeMesh = null;
    _meshEdges = null;
    _geometryEdges = null;
    _highlightedSrfs = [];
    _highlightedPlane = -1;
    _colorPallet = null;
    _selectionColor = null;
    _fallbackColor = null;
    _originalColors = {}; // To store original colors for restoration

    constructor() {
        this._colorPallet = [
            new Color("rgb(140, 190, 230)"),
            new Color("rgb(40, 120, 130)"),
            new Color("rgb(190, 230, 180)"),
            new Color("rgb(160, 170, 240)"),
            new Color("rgb(210, 210, 255)"),
            new Color("rgb(175, 180, 190)"),
        ];
        this._selectionColor = new Color("rgb(230, 255, 150)");
        this._fallbackColor = new Color("rgb(0, 65, 100)");
    }

    generateMeshFromRawData(meshObject) {
        this._mesh = meshObject;
        const rawMesh = this._generateMeshFromIndex(
            this._mesh.node_coordinates,
            this._mesh.triangles
        );
        const bufgeo = new BufferGeometry();
        const buffer = new BufferAttribute(rawMesh, 3);
        const colorBuffer = this._generateColorMap(meshObject);
        bufgeo.setAttribute("position", buffer);
        bufgeo.setAttribute("color", colorBuffer);
        buffer.setUsage(DynamicDrawUsage);
        bufgeo.computeVertexNormals();
        bufgeo.computeBoundingSphere();

        const parameters = {
            vertexColors: true,
            polygonOffset: true,
            polygonOffsetFactor: 1, // positive value pushes polygon further away
            polygonOffsetUnits: 1,
        };

        const material = new MeshBasicMaterial(parameters);
        this._threeMesh = new Mesh(bufgeo, material);

        this._meshEdges = this._createLinesFromNodesAndEdges(meshObject.node_coordinates, meshObject.edges);

        this.scaleToFit(this._threeMesh); // scale mesh
        this.scaleToFit(this._meshEdges); // scale edges
    }

    scaleToFit(object) {
        const maxSize = 25;
        const box = new Box3().setFromObject(object);
        const size = new Vector3();
        box.getSize(size);

        const maxDim = Math.max(size.x, size.y, size.z);

        const scale = maxSize / maxDim;

        object.scale.set(scale, scale, scale);
        object.updateMatrixWorld(true);
    }

    _createLinesFromNodesAndEdges(node_coordinates, edges) {
        const group = new Group();
        const points = [];

        for (let i = 0; i < edges.length; i += 2) {
            const idx1 = edges[i] * 3;
            const idx2 = edges[i + 1] * 3;

            points.push(
                node_coordinates[idx1], node_coordinates[idx1 + 1], node_coordinates[idx1 + 2],
                node_coordinates[idx2], node_coordinates[idx2 + 1], node_coordinates[idx2 + 2]
            );
        }

        const geometry = new BufferGeometry();
        const vertices = new Float32Array(points);
        geometry.setAttribute('position', new BufferAttribute(vertices, 3));

        const material = new LineBasicMaterial({ color: 0x000000 });

        const lineSegments = new LineSegments(geometry, material);

        group.add(lineSegments);

        return group;
    }

    get highlightedSrfs() {
        return this._highlightedSrfs;
    }

    set highlightedSrfs(value) {
        if (value !== this._highlightedSrfs) {
            this._highlightedSrfs = value;
            // this.recolorFromColorMap(this._generateColorMap(this._mesh));
        }
    }

    _generateColorMap(meshObject) {
        const { triangle_surfaces, triangle_color_index } = meshObject;
        const max_indices = triangle_surfaces.length;
        const colorList = new ColorMap();
    
        const highlightedSet = new Set(this._highlightedSrfs);
    
        for (let i = 0; i < max_indices; i++) {
            const surfaceIndex = triangle_surfaces[i];
            if (highlightedSet.has(surfaceIndex)) {
                colorList.addTriangleColorFromColorReference(this._selectionColor);
            } else if (triangle_color_index !== null) {
                const colorIndex = triangle_color_index[i];
                const newColor = this._colorPallet[colorIndex] || this._fallbackColor;
                colorList.addTriangleColorFromColorReference(newColor);
            } else {
                colorList.addTriangleColorFromColorReference(this._fallbackColor);
            }
        }
    
        return colorList.getColorMap();
    }

    getModel() {
        return this._threeMesh;
    }

    getMeshEdges() {
        return this._meshEdges;
    }

    getGeometryEdges() {
        return this._geometryEdges;
    }

    recolorFromColorMap(colorMap) {
        this._threeMesh.geometry.setAttribute("color", colorMap);
        this._threeMesh.geometry.attributes.color.needsUpdate = true;
    }

    _generateMeshFromIndex(rawdata, index) {
        const newMesh = new Float32Array(index.length * 3);

        for (let j = 0; j < index.length; j++) {
            const idx = index[j] * 3;
            newMesh[j * 3] = rawdata[idx];
            newMesh[j * 3 + 1] = rawdata[idx + 1];
            newMesh[j * 3 + 2] = rawdata[idx + 2];
        }

        return newMesh;
    }

    generateColorFromIndex(rawcolordata, index) {
        return new Float32BufferAttribute(
            this._generateMeshFromIndex(rawcolordata, index),
            3
        );
    }

    highlightSurface(surfaceIndex) {
        const colorAttribute = this._threeMesh.geometry.attributes.color;
        const faces = this._mesh.triangles;

        if (!this._originalColors[surfaceIndex]) {
            this._originalColors[surfaceIndex] = [];
            for (let i = 0; i < faces.length; i++) {
                if (this._mesh.triangle_surfaces[i] === surfaceIndex) {
                    this._originalColors[surfaceIndex].push(
                        colorAttribute.getX(i * 3),
                        colorAttribute.getY(i * 3),
                        colorAttribute.getZ(i * 3)
                    );
                }
            }
        }

        for (let i = 0; i < faces.length; i++) {
            if (this._mesh.triangle_surfaces[i] === surfaceIndex) {
                colorAttribute.setXYZ(i * 3, this._selectionColor.r, this._selectionColor.g, this._selectionColor.b);
                colorAttribute.setXYZ(i * 3 + 1, this._selectionColor.r, this._selectionColor.g, this._selectionColor.b);
                colorAttribute.setXYZ(i * 3 + 2, this._selectionColor.r, this._selectionColor.g, this._selectionColor.b);
            }
        }

        colorAttribute.needsUpdate = true;
    }

    restoreSurfaceColor(surfaceIndex) {
        const colorAttribute = this._threeMesh.geometry.attributes.color;
        const faces = this._mesh.triangles;
        const originalColors = this._originalColors[surfaceIndex];

        if (originalColors) {
            let colorIndex = 0;
            for (let i = 0; i < faces.length; i++) {
                if (this._mesh.triangle_surfaces[i] === surfaceIndex) {
                    colorAttribute.setXYZ(i * 3, originalColors[colorIndex], originalColors[colorIndex + 1], originalColors[colorIndex + 2]);
                    colorAttribute.setXYZ(i * 3 + 1, originalColors[colorIndex], originalColors[colorIndex + 1], originalColors[colorIndex + 2]);
                    colorAttribute.setXYZ(i * 3 + 2, originalColors[colorIndex], originalColors[colorIndex + 1], originalColors[colorIndex + 2]);
                    colorIndex += 3;
                }
            }

            colorAttribute.needsUpdate = true;
        }
    }

    toggleSurfaceSelection(surfaceIndex) {
        if (this._highlightedSrfs.includes(surfaceIndex)) {
            this._highlightedSrfs = this._highlightedSrfs.filter(index => index !== surfaceIndex);
            this.restoreSurfaceColor(surfaceIndex);
        } else {
            this._highlightedSrfs.push(surfaceIndex);
            this.highlightSurface(surfaceIndex);
        }
    }

    clearCache() {
        // Clear original colors cache
        this._originalColors = {};
    }
}

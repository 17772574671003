export function setFaviconForTheme() {
  const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)');

  const linkElement = document.querySelector("link[rel~='icon']");
  if (!linkElement) {
    const headElement = document.getElementsByTagName('head')[0];
    const newLinkElement = document.createElement('link');
    newLinkElement.rel = 'icon';
    headElement.appendChild(newLinkElement);
  }

  if (prefersDarkScheme.matches) {
    linkElement.href = './assets/favicon_dark.ico';
  } else {
    linkElement.href = './assets/favicon_light.ico';
  }
}
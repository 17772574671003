'use strict';

import * as THREE from "three";
import {Coordinates} from "../models/Coordinates.js";

export class CameraFactory
{
    _fov = null;
    _aspect = null
    _near = null;
    _far = null;
    _position = null;
    _lookAt = null;

    constructor(width, height) {
        this._init(width, height);
    }

    _init(width, height)
    {
        this._aspect = width / height;
        this._fov = 45;
        this._near = 0.1;
        this._far = -1;
        this._position = new Coordinates(0, 100, 0);
        this._lookAt = new Coordinates(0, 0, 0);
    }

    getCameraObject()
    {
        // const camera = new THREE.PerspectiveCamera( this._fov, this._aspect, this._near, this._far );
        // camera.position.set( this._position.x, this._position.y, this._position.z );
        // camera.lookAt( this._lookAt.x, this._lookAt.y, this._lookAt.z );

        const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
        camera.position.set(30, 15, 15);
        // camera.lookAt(camera.position)

        return camera;
    }
    getMapCamera() {

        const camera = new THREE.PerspectiveCamera( 45, 1, 0.1, -1 );
        camera.position.set( 0, 100, 0 );

        return camera;

    }
}
"use strict";


import {MeshObject} from "../models/MeshObject.js";
import {Coordinates} from "../models/Coordinates.js";

export class MeshFactory
{
    constructor() {
    }

    createMeshModelFromData(meshdata)
    {
        if (meshdata === undefined || meshdata === null) {
            return null;
        }
        else {
            let node_coordinates = meshdata.nc ?? null;
            let edges = meshdata.ed ?? null;
            let triangles = meshdata.trg ? Array.from(meshdata.trg) : null;
            let node_corners = meshdata.node_cnrs ?? null;
            let edge_curves = meshdata.ed_crvs ?? null;
            let triangle_surfaces = meshdata.trg_srfs ?? null;
            let surface_colors = meshdata.srf_colors ?? null;
            let triangle_color_indicis = null;
            if (surface_colors != null && triangle_surfaces != null) {
                triangle_color_indicis = Array(triangle_surfaces.length).fill(0);
                for (let i = 0; i < triangle_color_indicis.length; i++) {
                    triangle_color_indicis[i] = surface_colors[triangle_surfaces[i]];
                }
            }
            const min_values = new Coordinates();
            const max_values = new Coordinates();
            this._generateMinMaxValues(node_coordinates, min_values, max_values);

            return new MeshObject(
                node_coordinates,
                edges,
                triangles,
                node_corners,
                edge_curves,
                triangle_surfaces,
                triangle_color_indicis,
                min_values,
                max_values
            );
        }
    }

    createEmptyMesh() {
        let empyGeometry = {
                        "nc": {
                            "data": "",
                            "shape": [
                                0,
                                2
                            ],
                            "dtype": "float64"
                        },
                        "trg": {
                            "data": "",
                            "shape": [
                                0,
                                2
                            ],
                            "dtype": "float64"
                        },
                        "ed": {
                            "data": "",
                            "shape": [
                                0,
                                2
                            ],
                            "dtype": "float64"
                        },
                        "node_cnrs": {
                            "data": "",
                            "shape": [
                                0
                            ],
                            "dtype": "float64"
                        },
                        "ed_crvs": {
                            "data": "",
                            "shape": [
                                0
                            ],
                            "dtype": "float64"
                        },
                        "trg_srfs": {
                            "data": "",
                            "shape": [
                                0
                            ],
                            "dtype": "float64"
                        }
                    };
        return this.createMeshModelFromData(empyGeometry);
    }

    _generateMinMaxValues(node_coordinates, min_values, max_values)
    {
        let min_x = 0;
        let min_y = 0;
        let min_z = 0;
        let max_x = 0;
        let max_y = 0;
        let max_z = 0;

        for (let i = 0; i < (node_coordinates.length / 3); i += 3)
        {
            let x = i;
            let y = i + 1;
            let z = i + 2;

            if (node_coordinates[x] < min_x) {min_x = node_coordinates[x];}
            if (node_coordinates[x] > max_x) {max_x = node_coordinates[x];}
            if (node_coordinates[y] < min_y) {min_y = node_coordinates[y];}
            if (node_coordinates[y] > max_y) {max_y = node_coordinates[y];}
            if (node_coordinates[z] < min_z) {min_z = node_coordinates[z];}
            if (node_coordinates[z] > max_z) {max_z = node_coordinates[z];}
        }

        min_values.x = min_x;
        min_values.y = min_y;
        min_values.z = min_z;
        max_values.x = max_x;
        max_values.y = max_y;
        max_values.z = max_z;
    }
}
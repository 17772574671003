'use strict';

import * as THREE from "three";
import WebGL from "three/addons/capabilities/WebGL.js";
import {OrbitControls} from "three/addons/controls/OrbitControls.js";
import { TransformControls } from 'three/addons/controls/TransformControls.js';
import {
    BoxGeometry,
    Euler,
    Mesh,
    MeshBasicMaterial,
    OrthographicCamera,
    PerspectiveCamera,
    Scene,
    Vector3,
    WebGLRenderer,
} from 'three';
import { ModelGenerator } from './ModelGenerator';

export class Renderer
{
    _mesh = null;
    _renderer = null;
    _controls = null;
    _scene = null;
    _camera = null;
    _container = null;
    _animationCallback = null;
    _pointer = null;
    _raycaster = null;
    _modelGenerator = null;
    _isModelAction = null;

    constructor(
        width, 
        height, 
        container
    ) {
        this._init(width, height, container);
        this._modelGenerator = new ModelGenerator();
    }

    _init(width, height, container)
    {
        this._renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
        this._renderer.setPixelRatio( window.devicePixelRatio );
        this.setSize( width, height );
        this._renderer.setClearColor('#d2e1eb', 1);
        this._container = document.getElementById(container);
        this._container.appendChild( this._renderer.domElement );
    }

    run()
    {
        if ( WebGL.isWebGLAvailable() ) {
            // Initiate function or other initializations here
            this._animationCallback();

        } else {
            const warning = WebGL.getWebGLErrorMessage();
            this._container.appendChild( warning );
        }
    }

    animate()
    {
        this._controls.update();
        this._renderer.render( this._scene, this._camera );
        
    }
    setMesh(mesh)
    {
        this._mesh = mesh;
    }

    getMesh()
    {
        return _mesh;
    }

    setSize(width, height)
    {
        this._renderer.setSize(width, height);
    }

    getControls() {
        return this._controls;
    }

    setControls(value) {
        this._controls = value;
    }

    getScene() {
        return this._scene;
    }

    setScene(value) {
        this._scene = value;
    }

    getCamera() {
        return this._camera;
    }

    setCamera(value) {
        this._camera = value;
    }

    setRaycaster(raycaster)
    {
        this._raycaster = raycaster;
    }

    setPointer(pointer)
    {
        this._pointer = pointer;
    }

    getDomElement()
    {
        return this._renderer.domElement;
    }

    setAnimationCallback(callback)
    {
        this._animationCallback = callback;
    }

    getAnimationCallback()
    {
        return this._animationCallback;
    }
}
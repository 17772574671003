'use strict';

export class Coordinates
{
    _x = 0;
    _y = 0;
    _z = 0;

    constructor(x, y, z) {

        this._x = x;
        this._y = y;
        this._z = z;
    }


    get x() {
        return this._x;
    }

    set x(value) {
        this._x = value;
    }

    get y() {
        return this._y;
    }

    set y(value) {
        this._y = value;
    }

    get z() {
        return this._z;
    }

    set z(value) {
        this._z = value;
    }
}
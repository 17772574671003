'use strict';

import * as THREE from "three";

export class SceneFactory
{
    _backround = null;

    constructor() {
        this._init();
    }

    _init()
    {
        this._backround = {
            color: '#d2e1eb',
            texture: null
        };
    }

    getSceneObject()
    {
        const scene = new THREE.Scene();
        scene.background = this._backround;

        return scene;
    }
}
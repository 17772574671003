'use strict';

export class ResponseConverter
{
    constructor() {
    }

    convert(json) {
        let modeldata = {};
        for (let part in json) {
            modeldata[part] = this._convertPart(json[part]);
        }
        return modeldata;
    }

    _convertPart(json)
    {
        if ( json === undefined) {
            return null;
        }
        else if (json.data === undefined) {
            return json
        }
        else {
            // use nc for coords and trg for index
            const data = json.data;
            const shape = json.shape;
            const dtype = json.dtype;
            const binaryString = atob(data);
            let len = binaryString.length;
            let bytes = new Uint8Array(len);

            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }

            let TypedArray;
            switch (dtype) {
                case 'float64':
                    TypedArray = Float64Array;
                    break;
                case 'float32':
                    TypedArray = Float32Array;
                    break;
                case 'int32':
                    TypedArray = Int32Array;
                    break;
                case 'uint8':
                    TypedArray = Uint8Array;
                    break;
                default:
                    throw new Error(`Unknown dtype: ${dtype}`);
            }

            return new TypedArray(bytes.buffer);
        }
    }
}
"use strict";

import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import { TrackballControls } from "three/addons/controls/TrackballControls";
import { TransformControls } from "three/addons/controls/TransformControls.js";
import { FontLoader } from "three/addons/loaders/FontLoader.js";
import { TextGeometry } from "three/addons/geometries/TextGeometry.js";
import {
    BoxGeometry,
    Mesh,
    MeshBasicMaterial,
    Scene,
    WebGLRenderer,
    PerspectiveCamera,
    Vector3,
    Quaternion,
} from "three";

export class MapRenderer {
    _mesh = null;
    _meshCamera = null;
    _meshControls = null;
    _box = null;
    _renderer = null;
    _controls = null;
    _transformControls = null;
    _scene = null;
    _camera = null;
    _container = null;
    _animationCallback = null;
    _pointer = null;
    _raycaster = null;
    _boxInitialPosition = null;
    _isMapActionArrow = null;
    _isMapTransforming = null;
    _isMapAction = null;
    _isMeshAction = null;

    _lastPosition = new Vector3();
    _lastQuaternion = new Quaternion();
    _lastCameraPosition = new Vector3();
    _lastCameraQuaternion = new Quaternion();

    _textMeshX = new Mesh();
    _textMeshY = new Mesh();
    _textMeshZ = new Mesh();

    _initialMapDistance = 2;

    _modelHash = null;

    constructor(container) {
        this.init(container);
    }

    init(container) {
        this._renderer = new WebGLRenderer({ antialias: true, preserveDrawingBuffer: true }); // map Renderer
        this._renderer.setSize(200, 200);
        this._renderer.domElement.style.position = "absolute";
        this._renderer.domElement.style.bottom = "100px"; // bottom space
        this._renderer.domElement.style.left = "0px";
        this._renderer.domElement.style.background = "#ff000000";
        this._renderer.setClearColor(0xff0000, 0);

        // save container
        this._container = document.getElementById(container);
        // add to view container
        this._container.appendChild(this._renderer.domElement);
    }

    initScene() {
        this._scene = new Scene(); // map Scene
    }
    initCamera() {
        //map Camera
        this._camera = new PerspectiveCamera(75, 1, 0.1, 1000); // TODO: camera adaptation
        this._camera.position.set(0, 0, this._initialMapDistance);
    }
    addBox() {
        const geometry = new BoxGeometry(1, 1, 1); // Create a dummy model for the map scene
        const material = new MeshBasicMaterial({ color: 0x00ff00 });
        this._box = new Mesh(geometry, material);
        this._box.position.set(0, 0, 0);
        this._box.visible = false; // Make the dummy box invisible
        this._scene.add(this._box);

        this._initialPosition = this._box.position.clone();
    }

    addControls() {
        this._controls = new TrackballControls(
            this._camera,
            this._renderer.domElement
        );

        this._controls.rotateSpeed = 5.0;
        this._controls.zoomSpeed = 1.5;
        this._controls.panSpeed = 2;
        this._controls.staticMoving = true;
        this._controls.dynamicDampingFactor = 1;

        this._controls.minDistance = this.mapDistance;
        this._controls.maxDistance = this.mapDistance;

        this._controls.noPan = true;
        this._controls.noZoom = true;

        this._addMeshEventsListener(); // control for mesh
        this._addMapEventsListener();
    }

    syncCameraAndAxis() {
        this.syncCameras(this._meshCamera, this._camera, this._initialMapDistance);
    }

    addTransformControls() {
        this._transformControls = new TransformControls(
            this._camera,
            this._renderer.domElement
        );

        this._transformControls.attach(this._box);
        this._scene.add(this._transformControls);

        this._transformControls.setSize(2);

        this._transformControls.addEventListener("mouseDown", () => {
            this._isMapActionArrow = true;
        });

        this._transformControls.addEventListener("mouseUp", () => {
            this._isMapActionArrow = false;
        });

        this._transformControls.addEventListener("change", () => {
            if (this._isMapActionArrow) {
                this._resetBoxPosition(); // fix position
            }
        });

        this._renderer.domElement.addEventListener(
            "wheel",
            this._handleScroll.bind(this),
            true
        );
    }

    _addMeshEventsListener() {
        this._meshControls.addEventListener("start", () => {
            this._isMapAction = false;
            this._isMeshAction = true;
        });

        this._meshControls.addEventListener("end", () => {
            this._isMeshAction = false;
            this._isMapAction = false;
        });

        this._meshControls.addEventListener("change", () => {
            if (this._isMeshAction) {
                this.syncCameras(this._meshCamera, this._camera, this._initialMapDistance);
            }
        });
    }

    _addMapEventsListener() {
        this._controls.addEventListener("start", (event) => {
            this._isMapAction = true;
            this._isMeshAction = false;
        });

        this._controls.addEventListener("end", () => {
            this._isMeshAction = false;
            this._isMapAction = false;
        });

        this._controls.addEventListener("change", () => {
            if (this._isMapAction) {
                this.syncCameras(
                    this._camera,
                    this._meshCamera,
                    this._meshCamera.position.distanceTo(
                        this.getMesh().position
                    )
                );
            }
        });
    }

    _handleScroll(event) {
        event.preventDefault();
        const zoomFactor = 1.1;
        if (event.deltaY < 0) {
            // Scrolled up
            this._meshCamera.position.multiplyScalar(1 / zoomFactor);
        } else {
            // Scrolled down
            this._meshCamera.position.multiplyScalar(zoomFactor);
        }
    }

    _resetBoxPosition() {
        this._box.position.copy(this._initialPosition); // fix box position
    }

    syncCameras(source, target, distance) {
        const sourceDirection = new Vector3();
        source.getWorldDirection(sourceDirection);

        const targetPosition = new Vector3();
        targetPosition
            .copy(this.getMesh().position)
            .add(sourceDirection.multiplyScalar(-distance));

        target.position.copy(targetPosition);
        target.quaternion.copy(source.quaternion);
        target.up.copy(source.up);

        target.updateProjectionMatrix();
    }

    addAxisLabel() {
        this.removeAxisLabel();
        // Load font and create text labels
        const loader = new FontLoader();
        loader.load("./assets/font.json", (font) => {
            const textMaterialX = new MeshBasicMaterial({
                color: 0xff0000,
            }); // Red for X
            const textMaterialY = new MeshBasicMaterial({
                color: 0x00ff00,
            }); // Green for Y
            const textMaterialZ = new MeshBasicMaterial({
                color: 0x0000ff,
            }); // Blue for Z

            const textGeometryX = new TextGeometry("X", {
                font: font,
                size: 0.2,
                height: 0.1,
            });
            const textGeometryY = new TextGeometry("Y", {
                font: font,
                size: 0.2,
                height: 0.1,
            });
            const textGeometryZ = new TextGeometry("Z", {
                font: font,
                size: 0.2,
                height: 0.1,
            });

            this._textMeshX = new Mesh(textGeometryX, textMaterialX);
            this._textMeshY = new Mesh(textGeometryY, textMaterialY);
            this._textMeshZ = new Mesh(textGeometryZ, textMaterialZ);

            this._textMeshX.position.set(1, 0, -0.1);
            this._textMeshY.position.set(0, 1, -0.1);
            this._textMeshZ.position.set(-0.1, 0, 1);

            this._scene.add(this._textMeshX);
            this._scene.add(this._textMeshY);
            this._scene.add(this._textMeshZ);
        });
    }

    removeMap() {
        this.removeAxisLabel();
        this._renderer?.clear();
        this.clearScene(this._scene);
    }

    clearScene(scene) {
        while (scene.children.length > 0) {
            const object = scene.children[0];
            if (object.geometry) {
                object.geometry.dispose();
            }
            if (object.material) {
                if (Array.isArray(object.material)) {
                    object.material.forEach(material => material.dispose());
                } else {
                    object.material.dispose();
                }
            }
            scene.remove(object);
        }
    }

    removeAxisLabel() {
        if (!this._textMeshX || !this._textMeshY || !this._textMeshZ) {
            return;
        }
        const axis = [this._textMeshX, this._textMeshY, this._textMeshZ];
        axis.forEach((res) => {
            res.geometry.dispose();
            res.material.dispose();
            this._scene.remove(res);
        });
    }

    updateTextOrientation() {
        if (!this._textMeshX) {
            return;
        }
        const cameraQuaternion = this._camera.quaternion.clone();
        this._textMeshX.quaternion.copy(cameraQuaternion);
        this._textMeshY.quaternion.copy(cameraQuaternion);
        this._textMeshZ.quaternion.copy(cameraQuaternion);
    }

    animate() {
        if(this.getModelHash()){
            this._controls.update();
            this.updateTextOrientation();
            this._renderer.render(this._scene, this._camera);
        }
    }

    setMesh(value) {
        this._mesh = value;
    }
    getMesh() {
        return this._mesh;
    }
    setMeshCamera(value) {
        this._meshCamera = value;
    }
    getMeshCamera() {
        return this._meshCamera;
    }
    setMeshControls(value) {
        this._meshControls = value;
    }
    getMeshControls() {
        return this._meshControls;
    }

    getDomElement() {
        return this._renderer.domElement;
    }

    setModelHash(value) {
        this._modelHash = value;
    }

    getModelHash() {
        return this._modelHash;
    }
}

'use strict';

import {Color} from "three";
import {ColorMap} from "../models/ColorMap.js";

export class SolutionColorCalculator
{
    _calculationCache = {};
    _legend = null;

    constructor() {
    }

    calculateColorMap(solutionObject)
    {
        this._legend = solutionObject.legend;
        let dataLength = solutionObject.colorData.length;
        let colorMap = new ColorMap();

        for (let i = 0; i < dataLength; i++)
        {
            colorMap.addVertexColorFromObject(this._getColor(solutionObject.colorData[i]));
        }

        return colorMap;
    }

    _getColor(colorArrow)
    {
        let colorCacheKey = colorArrow.toString();
        if (this._calculationCache.hasOwnProperty(colorCacheKey) !== false) {
            return this._calculationCache[colorCacheKey];
        }
        else {
            this._calculationCache[colorCacheKey] = this._calculateColorFromReference(colorArrow, colorCacheKey);
            return this._calculationCache[colorCacheKey];
        }
    }

    _calculateColorFromReference(colorArrow, colorCacheKey)
    {
        if (this._legend.colorIndex.hasOwnProperty(colorCacheKey)) {
            return this._legend.colorIndex[colorCacheKey];
        }
        else {
            let minReference = this._getNearstMinimum(colorArrow);
            let maxReference = this._getNearstMaximum(colorArrow);

            let differenceComplete = maxReference - minReference;
            let differencePiece = colorArrow - minReference;
            let delta = differencePiece / differenceComplete;
            let minColor = this._legend.colorIndex[minReference.toString()];
            let maxColor = this._legend.colorIndex[maxReference.toString()];

            return new Color(
                this._calculateColor(minColor.r, maxColor.r, delta),
                this._calculateColor(minColor.g, maxColor.g, delta),
                this._calculateColor(minColor.b, maxColor.b, delta)
            );
        }
    }

    _getNearstMinimum(colorArrow)
    {
        let minimum = -65535;

        for (let key in this._legend.colorKeyReference)
        {
            if (this._legend.colorKeyReference[key] > minimum && this._legend.colorKeyReference[key] < colorArrow) {
                minimum = this._legend.colorKeyReference[key];
            }
        }

        return minimum;
    }

    _getNearstMaximum(colorArrow)
    {
        let maximum = 65535;

        for (let key in this._legend.colorKeyReference)
        {
            if (this._legend.colorKeyReference[key] < maximum && this._legend.colorKeyReference[key] > colorArrow) {
                maximum = this._legend.colorKeyReference[key];
            }
        }

        return maximum;
    }

    _calculateColor(min, max, delta)
    {
        if (min === max) {
            return min;
        }
        else if (min < max) {
            let difference = max - min;
            return min + difference * delta;
        }
        else {
            let difference = min - max;
            return min - difference * delta;
        }
    }
}
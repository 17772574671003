'use strict';

export class LegendColorIndex
{
    _colorIndex = {};
    _colorKeyReference = {};
    _colorIndexSize = 0;

    constructor(colorIndex, colorKeyReference) {
        this._colorIndex = colorIndex;
        this._colorKeyReference = colorKeyReference;
        this._colorIndexSize = Object.keys(colorIndex).length;
    }


    get colorIndex() {
        return this._colorIndex;
    }

    get colorKeyReference() {
        return this._colorKeyReference;
    }

    get colorIndexSize() {
        return this._colorIndexSize;
    }
}
"use strict";

import {Float32BufferAttribute} from "three";

export class ColorMap
{
    _colorMap = [];

    constructor() {
    }

    addVertexColor(r, g, b)
    {
        this._colorMap.push(r);
        this._colorMap.push(g);
        this._colorMap.push(b);
    }

    addVertexColorFromObject(colorObject)
    {
        this.addVertexColor(colorObject.r, colorObject.g, colorObject.b);
    }

    addTriangleColor(r, g, b)
    {
        // 3 times for every point one
        this.addVertexColor(r, g, b);
        this.addVertexColor(r, g, b);
        this.addVertexColor(r, g, b);
    }

    addVertexColorFromColorReference(color)
    {
        this.addVertexColor(color.r, color.g, color.b);
    }

    addTriangleColorFromColorReference(color)
    {
        this.addTriangleColor(color.r, color.g, color.b);
    }

    getColorMap()
    {
        return new Float32BufferAttribute(this._colorMap, 3);
    }

    getColorMapAsArray()
    {
        return this._colorMap;
    }
}
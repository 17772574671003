"use strict";

export class MeshObject
{
    _min = null;
    _max = null;
    _node_coordinates = null;
    _edges = null;
    _triangles = null;
    _node_corners = null;
    _edge_curves = null;
    _triangle_surfaces = null;
    _triangle_color_indicis = null

    constructor(
        node_coordinates,
        edges,
        triangles,
        node_corners,
        edge_curves,
        triangle_surfaces,
        triangle_color_indicis,
        min_values,
        max_values
    ) {
        this._node_coordinates = node_coordinates;
        this._edges = edges;
        this._triangles = triangles;
        this._node_corners = node_corners;
        this._edge_curves = edge_curves;
        this._triangle_surfaces = triangle_surfaces;
        this._triangle_color_indicis = triangle_color_indicis;
        this._min_values = min_values;
        this._max_values = max_values;
    }


    get node_coordinates() {
        return this._node_coordinates;
    }

    get edges() {
        return this._edges;
    }

    get triangles() {
        return this._triangles;
    }

    get node_corners() {
        return this._node_corners;
    }

    get edge_curves() {
        return this._edge_curves;
    }

    get triangle_surfaces() {
        return this._triangle_surfaces;
    }

    get min_values() {
        return this._min_values;
    }

    get max_values() {
        return this._max_values;
    }

    get triangle_color_index() {
        return this._triangle_color_indicis;
    }
}
codeEditor.setOption("extraKeys", {
  Tab: function (cm) {
    const selections = cm.listSelections();
    cm.operation(function () {
      selections.forEach(function (selection) {
        const fromLine = selection.head.line;
        const toLine = selection.anchor.line;
        for (
          let i = Math.min(fromLine, toLine);
          i <= Math.max(fromLine, toLine);
          i++
        ) {
          cm.replaceRange("    ", { line: i, ch: 0 });
        }
      });
    });
  },
  "Shift-Tab": function (cm) {
    const selections = cm.listSelections();
    cm.operation(function () {
      selections.forEach(function (selection) {
        const fromLine = selection.head.line;
        const toLine = selection.anchor.line;
        for (
          let i = Math.min(fromLine, toLine);
          i <= Math.max(fromLine, toLine);
          i++
        ) {
          const line = cm.getLine(i);
          if (line.startsWith("    ")) {
            cm.replaceRange("", { line: i, ch: 0 }, { line: i, ch: 4 });
          }
        }
      });
    });
  },
});
